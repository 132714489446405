export default {
  schema: 'iglu:be.vrt/context_search/jsonschema/1-1-2',
  data: {
    searchterm: '$seste',
    suggestsearchterm: '$sesst',
    searchfilter: '$sesfi',
    searchtype: '$sesty',
    searchcount: '$sesco',
  },
}
