export default {
  schema: 'iglu:be.vrt/context_listelement/jsonschema/1-1-0',
  data: {
    listelementtitle: '$leti',
    listelementposition: '$lepo',
    listelementcount: '$leco',
    // optional,
    listelementsubtitle: '$lest',
    listelementtype: '$lety',
  },
}
