export default {
  schema: 'iglu:be.vrt/context_page/jsonschema/1-1-0',
  data: {
    pagename: '$pana',
    touchpointbrand: '$patb',
    contentbrand: '$pacb',
    // optional,
    pageurl: '$url()',
    pagetitle: '$title()',
    domain: '$domain()',
    pagepath: '$path()',
    //        pageparams: '$params()', // TODO : discuss with Dieter&Koen array vs string
    pagefragment: '$fragment()',
    pagelanguage: '$lang()',
    sitesection1: '$pas1',
    sitesection2: '$pas2',
    sitesection3: '$pas3',
    sitesection4: '$pas4',
    topics: '$pato',
    labels: '$pala',
    category: '$paca',
    cms: '$pacms',
    programbrand: '$papb',
    pageid: '$paid',
    viewportsize: '$viewport()',
  },
}
