import bannerTemplates from './banner'
import contextTemplates from './contexts/index'
import linkTemplates from './link'
import listTemplates from './list'
import mediaTemplates from './media'
import pageTemplates from './page'

export default {
  ...pageTemplates,
  ...bannerTemplates,
  ...contextTemplates,
  ...linkTemplates,
  ...listTemplates,
  ...mediaTemplates,
}
