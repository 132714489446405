export default {
  schema: 'iglu:be.vrt/context_content_sport/jsonschema/1-1-0',
  data: {
    sportname: '$cssn',

    // optional
    sportcategory: '$cssc',
    competitionname: '$cscn',
    competitionregion: '$cscr',
  },
}
