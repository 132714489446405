import pageContextTemplate from './contexts/page_1-1-0'

const templates = {}

templates.page_impression_1_1_0 = {
  event: {
    schema: 'iglu:be.vrt/page_impression/jsonschema/1-1-0',
    data: {
      timestamp: '$now()',
    },
  },
  context: [pageContextTemplate],
}

templates.page_hidden_1_1_0 = {
  event: {
    schema: 'iglu:be.vrt/page_hidden/jsonschema/1-1-0',
    data: {
      timestamp: '$now()',
    },
  },
  context: [pageContextTemplate],
}

templates.page_visible_1_1_0 = {
  event: {
    schema: 'iglu:be.vrt/page_visible/jsonschema/1-1-0',
    data: {
      timestamp: '$now()',
    },
  },
  context: [pageContextTemplate],
}

templates.page_close_1_1_0 = {
  event: {
    schema: 'iglu:be.vrt/page_close/jsonschema/1-1-0',
    data: {
      timestamp: '$now()',
    },
  },
  context: [pageContextTemplate],
}

export default templates
